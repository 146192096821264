import axios from "axios";

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

export const http = (store) => (next) => async (action) => {
  if (action.url) {
    try {
      action.result = await request({
        method: action.method,
        url: action.url,
        body: action.body,
        params: action.params,
      });
    } catch (error) {
      console.log(error);
    }
  }
  return next(action);
};
