import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { CheckboxField } from "../../components/Checkbox";
import Container from "@material-ui/core/Container";
import { Button } from "../../components/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "../../store/orders";
import { cityData } from "../../data/city";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { getStores } from 'store/stores';

const Delivery = () => {
  const classes = useStyles();
  const [alignment, setAlignment] = useState("maintenant");
  const [value, setValue] = useState(true);

  const [bounds, setBounds] = useState();
  const [searchStreetOptions, setSearchStreetOptions] = useState({
    componentRestrictions: { country: "fr" },
    types: ["address"],
    bounds:
      bounds !== undefined &&
      new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(bounds.Sa.i, bounds.Sa.j),
        new window.google.maps.LatLng(bounds.Ya.i, bounds.Ya.j)
      ),
    strictBounds: true,
  });

  useEffect(() => {
    setSearchStreetOptions({
      componentRestrictions: { country: "fr" },
      types: ["address"],
      bounds:
        bounds !== undefined &&
        new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(bounds.Sa.i, bounds.Sa.j),
          new window.google.maps.LatLng(bounds.Ya.i, bounds.Ya.j)
        ),
      strictBounds: true,
    });
  }, [bounds]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { city, zip, street, typeDelivery, saveInfoAddress } = useSelector(
    (state) => state.reducerOrder.order
  );
  const [postal, setPostal] = useState("");
  const [streetMap, setStreet] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    city && setPostal(city);
    street && setStreet(street);
  }, []);

  useEffect(() => {
data.length>0 &&  dispatch(updateOrder({store:data[0]})).then(()=>history.push('/order-time?type=delivery'))
  }, [data])


  const handelUpdateOrder = () => {
    city &&
        dispatch(getStores(city.substr(0, city.indexOf(" ")))).then(
          (res) =>
            typeof res.result["data"] !== "undefined" &&
            setData(res.result.data)
        )
  };

  const handleChange = (event) => {
    setValue(event.target.checked);
    dispatch(updateOrder({ saveInfoAddress: event.target.checked }));
  };

  const handleAlignment = (event, newVal) => {
    dispatch(updateOrder({ typeDelivery: newVal }));
    setAlignment(newVal);
  };

  const handleChangePostal = (address) => {
    setPostal(address);
  };

  const handleSelectPostal = (address) => {
    setPostal(address);
    geocodeByAddress(address)
      .then((results) => setBounds(results[0].geometry.bounds))
      .catch((error) => console.error(error));
    dispatch(updateOrder({ city: address }));
  };

  const handleChangeStreet = (address) => {
    setStreet(address);
  };

  const handleSelectStreet = (address) => {
    setStreet(address);
    dispatch(updateOrder({ street: address }));
  };
  const searchOptions = {
    componentRestrictions: { country: "fr" },
    types: ["(regions)"],
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" component="h2" align="center">
        QUAND SOUHAITEZ-VOUS COMMANDER ?
      </Typography>
      <Grid container justify="center">
        <ToggleButtonGroup
          value={typeDelivery}
          exclusive
          size="small"
          style={{ marginTop: 10, background: "#fff" }}
          onChange={handleAlignment}
        >
          <ToggleButton value="maintenant" aria-label="left aligned">
            <span>Maintenant</span>
          </ToggleButton>
          <ToggleButton value="plusTard" aria-label="centered">
            <span>Plus tard</span>
          </ToggleButton>
        </ToggleButtonGroup>

        <Grid container justify="center">
          <div className={classes.paper}>
            <Grid container justify="left">
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginBottom: 20 }}
              >
                <PlacesAutocomplete
                  value={postal}
                  onChange={handleChangePostal}
                  onSelect={handleSelectPostal}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <TextField
                        size="small"
                        label="VOTRE CODE POSTAL"
                        variant="outlined"
                        {...getInputProps({
                          className: "location-search-input",
                        })}
                        style={{
                          width: "100%",
                          marginBottom: 10,
                          background: "#fff",
                        }}
                      />

                      <div
                        className="autocomplete-dropdown-container"
                        style={{ zIndex: 10 }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#FAD035", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              {suggestion.types.includes("postal_code") && (
                                <span>
                                  {suggestion.terms[0].value}{" "}
                                  {suggestion.terms[1].value}
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>

              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginBottom: 20 }}
              >
                <TextField
                  size="small"
                  label="NUMÉRO DE RUE"
                  value={zip}
                  variant="outlined"
                  onChange={(event) =>
                    dispatch(updateOrder({ zip: event.target.value }))
                  }
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    background: "#fff",
                  }}
                />
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <PlacesAutocomplete
                  value={`${streetMap}`}
                  onChange={handleChangeStreet}
                  onSelect={handleSelectStreet}
                  searchOptions={searchStreetOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <TextField
                        {...getInputProps({
                          className: "location-search-input",
                        })}
                        size="small"
                        label="RUE"
                        variant="outlined"
                        style={{
                          width: "100%",
                          marginBottom: 10,
                          background: "#fff",
                        }}
                      />
                      <div
                        className="autocomplete-dropdown-container"
                        style={{ zIndex: 10 }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#FAD035", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              {suggestion.types.includes("route") && (
                                <span>{suggestion.terms[0].value}</span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <p style={{ marginTop: 15 }}>
                  Les instructions de livraison (étage, digicode, etc.) pourront
                  être ajoutées lors de la validation de votre commande
                </p>
                <CheckboxField
                  checked={saveInfoAddress}
                  onChange={handleChange}
                  name="checked"
                  label="SE SOUVENIR DE MES INFORMATIONS"
                />
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 30,
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                handleAction={() => history.goBack()}
              >
                Précédent
              </Button>
              <Button
                variant="contained"
                color="primary"
                handleAction={() => handelUpdateOrder()}
              >
                SUIVANT
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Delivery;
