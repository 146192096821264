import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: 'center',
    background: '#F7F7F7',
    border: '5px solid #FAD035',
    borderRadius: 25,
    color: theme.palette.text.secondary,
    width: 140,
    cursor: 'pointer'
  },
  icn: {
    height: 100,
  }
}));
