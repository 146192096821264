import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Link,useParams } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    borderRadius: 15,
    textAlign: 'center',
    boxShadow: '0px 0px 25px -9px rgba(0,0,0,0.33)',
    outline: 'none',
    background: '#fff',
    padding: '0 10px'
  },
  media: {
    height: 95,
  },
  price: {
    borderRadius: 20,
    padding: '5px 20px',
  },
});
function ItemCategory(props) {
  const classes = useStyles();
  const params = useParams();

  return (
    <>
      <Link to={`/products/category/${params.storeId}/${props.index}`} style={{marginRight:15,width: 130}}>
        <Card
          elevation={0}
          className={classes.root}
          style={props.style}
          onClick={props.handleCategory}
        >
          <img src={props.image} className={classes.media} alt='' />
          <Typography
            gutterBottom
            variant='h6'
            component='h2'
            style={{fontSize: 14,height: 35,lineHeight: 1}}
          >
            {props.name}
          </Typography>
        </Card>
      </Link>
    </>
  );
}

export default ItemCategory;
