import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  item: {
    background: '#fff',
    width: '100%',
    padding: 15,
    marginBottom: 20,
    borderRadius: 7,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#eee',
    }
  },
  edit:{
    fontSize: 12,
    textTransform: 'uppercase'
  },
  title: {
    margin: 0,
  },
  description: {
    margin: 0,
  },
}));
