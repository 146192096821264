import React,{useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Button } from '../../components/Button';
import Typography from '@material-ui/core/Typography';
import tiramisu_street from '../../assets/picture_category/tiramisu_street.png';
import { useHistory } from 'react-router-dom';
import { addProduct, updateProduct } from '../../store/cart';
import { useDispatch, useSelector } from 'react-redux';
import StaticBasket from '../ProductMenu/staticBasket';
import StaticBasketMobile from '../ProductMenu/staticBasketMobile';
import Hidden from '@material-ui/core/Hidden';

const Item = ({ data }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.Cart.cart);

  const handleAddProduct = (productId, name, price,tva,suggestion_produits) => {
    if (cart.every((item) => item.productId !== productId)) {
      dispatch(addProduct({ productId, name, price,tva,suggestion_produits, count: 1 }));
    } else {
      dispatch(updateProduct(productId));
    }
  }

  return (
    <>
      <div
        style={{
          borderRadius: 15,
          textAlign: 'center',
          boxShadow: '0px 0px 25px -9px rgba(0,0,0,0.33)',
          outline: 'none',
          background: '#fff',
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <img src={data.Photo && process.env.REACT_APP_API_URL + data.Photo.url}
        style={{
          height: 115,
        }} alt='' />

        <Typography
          gutterBottom
          variant='h6'
          component='h2'
          style={{ fontSize: 15 }}
        >
          {data.nom}
        </Typography>
        <Button
          variant='contained'
          size='small'
          color='primary'
          style={{
            borderRadius: 20,
            padding: '5px 20px',
          }}
          handleAction={() => handleAddProduct( data.id, data.nom, data.PrixTTC, data.tva, data.suggestion_produits )}
        >
          {data.PrixTTC.toFixed(2)} €
        </Button>
      </div>
    </>
  );
};

const Suggestion = () => {
  const history = useHistory();
  const cart = useSelector((state) => state.Cart.cart);

  useEffect(() => {
    cart.length==0 && history.push('/products/category/2')
  }, [])
  return (
    <Container maxWidth='xs'>
      <Typography
        variant='h5'
        component='h2'
        align='center'
        style={{ marginBottom: 30 }}
      >
        PRENDREZ VOUS AUTRE CHOSE ?
      </Typography>
      <Grid container justify='center' spacing={3}>
      {cart.map((item,index)=>(
      item.suggestion_produits && item.suggestion_produits.map((product, index) => (
          <Grid item md={6}>
            <Item data={product} />
          </Grid>
        ))
      ))}
        
      </Grid>
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
      <Button
          variant='outlined'
          color='primary'
          handleAction={() => history.push('/products/category/2')}
        >
          Précédent
        </Button>
        <Button
          variant='contained'
          color='primary'
          handleAction={() => history.push('/register')}
        >
          CONTINUER
        </Button>
      </div>
      <Hidden smDown>
          <Grid item xs={3}>
            <StaticBasket />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <StaticBasketMobile />
        </Hidden>
    </Container>
  );
};

export default Suggestion;
