import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch, useSelector } from 'react-redux';
import ItemCategory from './itemCategory.jsx';
import { getCategories } from 'store/productsCategories';
import { useHistory, useParams } from 'react-router-dom';
import { getStoresById } from 'store/stores';
import { updateOrder } from 'store/orders';

export default function Categories() {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const order = useSelector((state) => state.reducerOrder.order);

  const setCategories = (data) => {
    setData(data);
    data.length>0 &&  history.push(`/products/category/${params.storeId}/${data[0].id}`);
  };

  useEffect(() => {
    order.store !== undefined
      ? setCategories(order.store.categories)
      : dispatch(getStoresById(params.storeId)).then((res) =>
          dispatch(updateOrder({ store: res.result.data }))
        );
  }, [order]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: 35, overflowX: 'auto' }}>
        {data.map((item, index) => (
          <ItemCategory
            key={index}
            index={item.id}
            image={item.Photo && process.env.REACT_APP_API_URL + item.Photo.url}
            name={item.Nom}
          />
        ))}
      </div>
    </div>
  );
}
