import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  toggleButton: {
    marginTop: 10,
    background: '#fff',
  },
  iconNavigation: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 15,
  },
  icon: {
    margin: 10,
    cursor: 'pointer',
  },
  activeIcon:{
    padding: 10,
    cursor: 'pointer',
    background: theme.palette.primary.main,
    borderRadius: 5
  },
  bottomNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  title:{
    marginBottom: 20
  }
}));
