import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(3),
    background: '#fff',
    width: '100%',
    textAlign:'left'
  }
}));
