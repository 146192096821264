import { ADD_PRODUCTS, REMOVE_PRODUCTS, UPDATE_PRODUCTS } from './constants';

const initialState = {
  cart: [],
};

const reducerCart = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCTS:
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };
    case REMOVE_PRODUCTS:
      return {
        ...state,
        cart: [...state.cart].filter(
          (item) => item.productId !== action.payload
        ),
      };
    case UPDATE_PRODUCTS:
      return {
        ...state,
        cart: [...state.cart].map((el) =>
          el.productId === action.payload
            ? { ...el, count: el.count + 1 }
            : el
        ),
      };

    default:
      return state;
  }
};
export default reducerCart;
