import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Button } from '../../components/Button';
import TextField from '@material-ui/core/TextField';
import { CheckboxField } from '../../components/Checkbox';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { tryRegister } from '../../store/register';
import { updateOrder } from "../../store/orders";

export default function Register() {
  const history = useHistory();
  const [value, setValue] = useState(true);
  const [data, setData] = useState({
    saveInfo: false,
    noAds: false,
    acceptedCgu: false,
  });
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.Cart.cart);
  const order = useSelector((state) => state.reducerOrder.order);

  const handleForm = (inputForm) => {
    setData({ ...data, ...inputForm });
  };

  const handleRegister = () => {
    dispatch(updateOrder({user:data})).then(()=>
    history.push(`/payment`)
    )
  };

  return (
    <Container maxWidth='xs'>
      <Grid container justify='center'>
        <div
          style={{
            width: '100%',
            background: 'rgba(0, 0, 0, 0.1)',
            padding: 15,
            margin: '0 0 25px 0',
          }}
        >
          <h3 style={{ margin: 0, paddingBottom: 10 }}>
            Information de livraison
          </h3>
          <div style={{ display: 'flex', marginBottom: 7 }}>
            <b>Adresse: </b>
        <p>{order.zip}, {order.street}</p>
          </div>
          <div style={{ display: 'flex', marginBottom: 7 }}>
            <b>Livré: </b>
        <p>{order.type=="delivery" ? order.orderTime == "now" ?"DÈS QUE POSSIBLE":`${order.orderDate} ${order.orderTime}`: "A EMPORTER"}</p>
          </div>
          <div style={{ display: 'flex', marginBottom: 7 }}>
            <b>Total: </b>
            <p>{cart.reduce(
              (a, b) => b['ingridients'] ? parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0) +
               b['ingridients'].reduce((c, d) => parseFloat(c) + (parseFloat(d['price'] * d['count']) || 0)
             ,0):
             parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0)
              ,
              0
            ).toFixed(2)} €</p>
          </div>
        </div>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            size='small'
            label='Nom'
            variant='outlined'
            onChange={(event) => handleForm({ nom: event.target.value })}
            style={{ width: '100%', marginBottom: 10, background: '#fff' }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            size='small'
            label='TÉLÉPHONE'
            variant='outlined'
            onChange={(event) => handleForm({ phone: event.target.value })}
            style={{ width: '100%', marginBottom: 10, background: '#fff' }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            size='small'
            label='EMAIL'
            variant='outlined'
            onChange={(event) => handleForm({ email: event.target.value })}
            style={{ width: '100%', marginBottom: 10, background: '#fff' }}
          />
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginBottom: 15 }}
        >
          <CheckboxField
            checked={data['saveInfo']}
            handleChange={(event) =>
              handleForm({ saveInfo: event.target.checked })
            }
            name='checked'
            label={
              <span style={{ fontSize: 14, color: '#999' }}>
                SE SOUVENIR DE MES INFORMATIONS
              </span>
            }
          />
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginBottom: 15 }}
        >
          <CheckboxField
            checked={value}
            handleChange={(event) =>
              handleForm({ noAds: event.target.checked })
            }
            name='checked'
            style={{ fontSize: 14, color: '#999' }}
            label={
              <span style={{ fontSize: 14, color: '#999' }}>
                JE NE SOUHAITE PAS RECEVOIR LES OFFRES PAR SMS ET PAR EMAIL
              </span>
            }
          />
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginBottom: 15 }}
        >
          <CheckboxField
            checked={value}
            handleChange={(event) =>
              handleForm({ acceptedCgu: event.target.checked })
            }
            name='checked'
            style={{ fontSize: 14, color: '#999' }}
            label={
              <span style={{ fontSize: 14, color: '#999' }}>
                En cochant cette case, j'accepte et je reconnais avoir pris
                connaissance des CONDITIONS GÉNÉRALES DE VENTE et de LA NOTICE
                DONNÉES PERSONNELLES
              </span>
            }
          />
        </Grid>

        <Button
          variant='contained'
          color='primary'
          handleAction={() => handleRegister()}
        >
          Suivant
        </Button>
      </Grid>
    </Container>
  );
}
