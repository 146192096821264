import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { useStyles } from './style';

export const ButtonWithIcon = ({ link, onClick, icon, title, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleOnClick = () => {
    onClick();
    history.push(link);
  };
  
  return (
    <Paper onClick={() => handleOnClick()} className={classes.paper}>
      <img src={icon} alt='' className={classes.icn} />
      <h3 style={{ textTransform: 'uppercase' }}>{title}</h3>
    </Paper>
  );
};
