import React from 'react';
import { Button as Btn } from '@material-ui/core';

export const Button = ({ children, handleAction,variant, ...props }) => {
  return (
    <Btn variant={variant} {...props} color='primary' onClick={() => handleAction()}>
      {children}
    </Btn>
  );
};
