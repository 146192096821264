import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Button } from "../../components/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Cash = () => {
  const { ref } = useParams();
  const history = useHistory();

  return (
    <Container maxWidth="xs">
      <Typography
        variant="h5"
        component="h2"
        align="center"
        style={{ marginBottom: 20, textTransform: "uppercase", fontSize: 20 }}
      >
        Commande enregistré
      </Typography>
      <Grid container justify="center" alignItems="center" direction="column">
        <h4>Votre commande a été enregistré avec succès</h4>
        <div>
          <Button
            variant="contained"
            color="primary"
            handleAction={() => history.replace("/")}
            size="large"
          >
            retour
          </Button>
        </div>
      </Grid>
    </Container>
  );
};

export default Cash;
