import { UPDATE, ADD_ORDER } from "./constants";

/**
 * Update order
 */
export const updateOrder = (value, name) => ({
  type: UPDATE,
  name,
  value,
});

/**
 * Create order
 */
export const createOrder = (data) => ({
  type: ADD_ORDER,
  url: `/orders`,
  method: "POST",
  params: data,
});
