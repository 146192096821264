import React from 'react';
import { Select as Slc } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { useStyles } from './style';

export const Select = ({ label, value, options, handleChange, ...props }) => {
  const classes = useStyles();

  

  return (
    <FormControl
      className={classes.formControl}
      size='small'
      variant='outlined'
      {...props}
    >
      {label&&<InputLabel id='cmd-label'>{label}</InputLabel>}
      <Slc
        labelId='cmd-label'
        value={value}
        label={label?label:''}
        onChange={handleChange}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item}>{item}</MenuItem>
        ))}
      </Slc>
    </FormControl>
  );
};
