import React, { useState,useEffect } from 'react';
import FormPickup from './components/FormPickup';
import List from './components/List';
import Map from './components/Map';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Button } from '../../components/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './style';
import { useDispatch } from 'react-redux';
import { getStores } from 'store/stores';
import { updateOrder } from 'store/orders';
import {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

const Pickup = () => {
  const classes = useStyles();
  const [alignment, setAlignment] = useState('left');
  const [view, setView] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [storeBehind, setStoreBehind] = useState([])
  const [latLngUser, setLatLngUser] = useState()

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleView = (value) => {
    setView(value);
  };

  const getStoresHandler=(search)=>{
    dispatch(getStores(search)).then((res) => typeof res.result['data'] !== 'undefined' && setData(res.result.data))
  }

  const selectStore = (store) =>{
    dispatch(updateOrder({ store })).then(()=>history.push('/order-time'))
  }
   //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
   const calcCrow=(lat1, lon1, lat2, lon2) =>
   {
     var R = 6371; // km
     var dLat = toRad(lat2-lat1);
     var dLon = toRad(lon2-lon1);
     var lat1 = toRad(lat1);
     var lat2 = toRad(lat2);

     var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
       Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
     var d = R * c;
     return d;
   }

   // Converts numeric degrees to radians
   const toRad=(Value)=> 
   {
       return Value * Math.PI / 180;
   }

  useEffect(() => {
    getStoresHandler("")
  }, [])

  useEffect(() => {

    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      setLatLngUser({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      data.map((item, index) =>
        geocodeByAddress(
          `${item.codepostal} ${item.Ville} ${item.complementdadresse}`
        )
          .then((results) => getLatLng(results[0]))
          .then((latLng) =>
            storeBehind.push({
              store: item,
              latLng,
              distance: calcCrow(
                position.coords.latitude,
                position.coords.longitude,
                latLng.lat,
                latLng.lng
              ),
            })
          )
          .catch((error) => console.error(error))
      );
    });
  }, [data])

  return (
    <Container maxWidth='sm'>
      <Typography
        variant='h5'
        component='h2'
        align='center'
        className={classes.title}
      >
        QUAND SOUHAITEZ-VOUS COMMANDER ?
      </Typography>
      <Grid container justify='center'>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          size='small'
          className={classes.toggleButton}
          onChange={handleAlignment}
          aria-label='text alignment'
        >
          <ToggleButton value='left' aria-label='left aligned'>
            <span>Maintenant</span>
          </ToggleButton>
          <ToggleButton value='center' aria-label='centered'>
            <span>Plus tard</span>
          </ToggleButton>
        </ToggleButtonGroup>

        <Grid item md={12}>
          <div className={classes.paper}>
            <div className={classes.iconNavigation}>
              <Icon
                onClick={() => handleView(1)}
                className={view === 1 ? classes.activeIcon : classes.icon}
              >
                search
              </Icon>
              <Icon
                onClick={() => handleView(2)}
                className={view === 2 ? classes.activeIcon : classes.icon}
              >
                format_list_bulleted
              </Icon>
              <Icon
                onClick={() => handleView(3)}
                className={view === 3 ? classes.activeIcon : classes.icon}
              >
                map
              </Icon>
            </div>
            {view === 1 && <FormPickup getStoresHandler={(value)=>getStoresHandler(value)} data={data} />}
            {view === 2 && <List  data={storeBehind}/>}
            {view === 3 && (
              <Map
                isMarkerShown
                googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBb_RXCNvyciUbkRgXOckFYsCsAaAItXBE&v=3.exp&libraries=geometry,drawing,places'
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `300px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                latLng={latLngUser}
                data={storeBehind}
                selectStore={(store)=>selectStore(store)}
              />
            )}
            <div className={classes.bottomNavigation}>
              <Button
                variant='contained'
                color='primary'
                handleAction={() => history.goBack()}
              >
                Précédent
              </Button>
             {/*  <Button
                variant='contained'
                color='primary'
                handleAction={() => history.push('/order-time')}
              >
                SUIVANT
              </Button>*/}
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Pickup;
