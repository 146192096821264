import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  bottomNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonDate: {
    width: '100%',
    marginTop: 20,
  },
  container: {
    width: '100%',
    textAlign: 'center',
    marginTop: 20
  },
  box: {
    background: '#fff',
    width: '100%',
    padding: 15,
    marginBottom: 20,
    borderRadius: 7,
    marginTop: 25,
  },
  title:{
    marginBottom: 20
  }
}));
