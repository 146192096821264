import { GET_INGREDIENTS } from './constants';

/**
 * Get categories ingredients
 */
export const getIngredients = (id) => ({
  type: GET_INGREDIENTS,
  url: `/ingredients?categories_ingredients[].id=${id}`,
  method: 'GET',
});
