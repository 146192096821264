import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import auComptoire from '../../assets/payment_type/au_comptoire.png';
import bankCard from '../../assets/payment_type/bank_card.png';
import Typography from '@material-ui/core/Typography';
import { ButtonWithIcon } from '../../components/ButtonWithIcon';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

const Payment = () => {
  const history = useHistory();
  const [token, setToken] = useState(null);

  const order = useSelector((state) => state.reducerOrder.order);
  const cart = useSelector((state) => state.Cart.cart);

  const handlePayment = (value) => {
    setToken(value);
    history.push(`/checkout/${value}`);
  };

  const cash = () => {
    axios
    .post('https://backoffice.splash360.fr/orders', {
      name: 'test',
      phone: 123,
      email: 'test@gmail.com',
      type: order.type == "pickup" ?"aemporter" : "livraison",
      date:moment(new Date()).toDate(),
      magasin:order.store,
      status:'encours',
      prix:cart.reduce(
        (a, b) => b['ingridients'] ? parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0) +
         b['ingridients'].reduce((c, d) => parseFloat(c) + (parseFloat(d['price'] * d['count']) || 0)
       ,0):
       parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0)
        ,
        0
      ).toFixed(2),
      commande: cart,
      name:order.user.nom,
      phone: order.user.phone,
      email:order.user.email
    })
    .then((res) => history.push(`/cash`))
    .catch((err) => console.log(err));
  }

  // Handle payline
  const initPayline = async () => {
    axios
    .post('https://backoffice.splash360.fr/orders', {
      name: 'test',
      phone: 123,
      email: 'test@gmail.com',
      type: order.type == "pickup" ?"aemporter" : "livraison",
      date:moment(new Date()).toDate(),
      magasin:order.store,
      status:'encours',
      prix:cart.reduce(
        (a, b) => b['ingridients'] ? parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0) +
         b['ingridients'].reduce((c, d) => parseFloat(c) + (parseFloat(d['price'] * d['count']) || 0)
       ,0):
       parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0)
        ,
        0
      ).toFixed(2),
      commande: cart,
      name:order.user.nom,
      phone: order.user.phone,
      email:order.user.email
    })
    .then((res) => (
      axios
      .post('https://backoffice.splash360.fr/payline', {
        mount: cart.reduce(
          (a, b) => b['ingridients'] ? parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0) +
           b['ingridients'].reduce((c, d) => parseFloat(c) + (parseFloat(d['price'] * d['count']) || 0)
         ,0):
         parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0)
          ,
          0
        ).toFixed(2) * 100 ,
        ref: moment().format('DD/MM/YYYY hh:mm'),
        redirectURL: `https://clickandcollect.splash360.fr/successful/${res.data.id}`,
        redirectCancelURL: `https://clickandcollect.splash360.fr/cancel/${res.data.id}`
      })
      .then((res) => handlePayment(res.data.token))
      .catch((err) => console.log(err))
    ))
    .catch((err) => console.log(err));

    
  };

  return (
    <Container maxWidth='xs'>
      <Typography variant='h5' component='h2' align='center'>
        Comment souhaitez vous régler ?
      </Typography>
      <Grid container justify='center'>
        <Grid item md={6}>
          <ButtonWithIcon
            icon={bankCard}
            onClick={() => initPayline()}
            title={'CARTE BLEUE'}
          />
        </Grid>
        <Grid item md={6}>
          <ButtonWithIcon
            icon={auComptoire}
            onClick={() => cash()}
            title={'à réception'}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Payment;
