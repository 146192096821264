import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useStyles } from './style';

export const Card = ({
  index,
  onClick,
  title,
  action,
  description,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Paper key={index} className={classes.item}
    onClick={onClick}>
      <div>
        <h6 className={classes.title}>{title}</h6>
        <p className={classes.description}>{description}</p>
      </div>
      <div>
        {action && (
          <p className={classes.edit} onClick={onClick}>
            Modifier
          </p>
        )}
      </div>
    </Paper>
  );
};
