import React from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Button } from '../../components/Button';
import Divider from '@material-ui/core/Divider';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory,useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { removeProduct } from '../../store/cart';

export default function StaticBasket() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const cart = useSelector((state) => state.Cart.cart);

  const handleRemoveProduct = (id) => {
    dispatch(removeProduct(id));
  };

  return (
    <Paper
      elevation={1}
      style={{
        padding: 15,
        width: 250,
        position: 'fixed',
        right: 15,
        top: 15,
        bottom: 15,
      }}
    >
      <div
        style={{
          background: '#dedede',
          margin: -15,
          padding: 15,
        }}
      >
        <p
          style={{
            fontSize: 13,
            marginBottom: 15,
          }}
        >
          AVEZ-VOUS UN CODE DE RÉDUCTION ?
        </p>
        <div style={{ display: 'flex' }}>
          <TextField
            variant='outlined'
            size='small'
            style={{ marginRight: 15 }}
            InputProps={{
              root: { background: '#fff' },
            }}
            label='Code de réduction'
          />
          <Button
            variant='contained'
            color='primary'
            handleAction={() => console.log('ok')}
          >
            Valider
          </Button>
        </div>
      </div>

      <p
        style={{
          fontWeight: 800,
          marginBottom: 25,
          marginTop: 30,
        }}
      >
        MA COMMANDE
      </p>
      <div>
        <PerfectScrollbar style={{ height: '50vh' }}>
          {cart.map((item, index) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <b style={{ fontSize: 13 }}>
                  {item.count} X {item.name}
                </b>
                <div style={{ display: 'flex', fontWeight: 800 }}>
                  {(item.price * item.count).toFixed(2)} <span>€</span>
                </div>
              </div>
              {typeof item['ingridients'] !== 'undefined' &&
                item['ingridients'].map((val) => (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontSize: 13 }}>
                    {val.name} ({val.count})
                  </div>
                {val.price &&  <div style={{ display: 'flex',fontSize: 13 }}>
                <span>+</span>  {(val.price * val.count).toFixed(2)} <span>€</span>
                </div>}
                  </div>
                ))}

             {location.pathname !=="/suggestion" && <div style={{ marginTop: 10 }}>
                <Button
                  variant='outlined'
                  color='primary'
                  handleAction={() => handleRemoveProduct(item.productId)}
                  size='small'
                >
                  Supprimer
                </Button>
                {/*<Button
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ marginLeft: 15 }}
                  handleAction={() => setEdit(true)}
                >
                  Modifier
                </Button>*/}
                </div> }
              <Divider light style={{ marginBottom: 15, marginTop: 15 }} />
            </div>
          ))}
        </PerfectScrollbar>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 15,
          }}
        >
          <p style={{ fontSize: 20, fontWeight: 800 }}>TOTAL</p>
          <p style={{ fontSize: 20, fontWeight: 800 }}>
            {cart.reduce(
              (a, b) => b['ingridients'] ? parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0) +
               b['ingridients'].reduce((c, d) => parseFloat(c) + (parseFloat(d['price'] * d['count']) || 0)
             ,0):
             parseFloat(a) + (parseFloat(b['price'] * b['count']) || 0)
              ,
              0
              

            ).toFixed(2)}
            €
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 15,
          }}
        >
          <Button
            variant='contained'
            color='primary'
            handleAction={() =>location.pathname !=="/suggestion" && history.push('/suggestion')}
            size='large'
            disabled={cart.length==0}
          >
            Confirmation
          </Button>
        </div>
      </div>
    </Paper>
  );
}
