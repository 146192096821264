import { UPDATE } from './constants';

const initialState = {
  order: {},
};

const reducerCart = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        order: { ...state.order, ...action.value },
      };
    default:
      return state;
  }
};
export default reducerCart;
