import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsByCategories } from 'store/products/actions';
import { getIngredients } from 'store/ingredients/actions';
import precedent from '../../assets/precedent.png';
import suivant from '../../assets/suivant.png';
import Badge from '@material-ui/core/Badge';

import { addProduct } from '../../store/cart';

export default function Details({ gotToList, currentProduct }) {
  const [selected, setSelected] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ingredientData, setIngredientData] = useState([]);
  const [step, setStep] = useState(0);
  const [maxStep, setMaxStep] = useState(0);
  const [countIngredient, setCountIngredient] = useState(0);
  const order = useSelector((state) => state.reducerOrder.order);
  const dispatch = useDispatch();

  useEffect(() => {
    categories.length > 0 &&
      dispatch(
        getIngredients(categories[step].categories_ingredient.id)
      ).then((res) => {
        countAvailableIngredient(categories[step].max,categories[step].categories_ingredient.id)
        setIngredientData(res.result.data)
      });
  }, [step, categories, dispatch]);

  useEffect(() => {
    setSelected([
      ...selected,
      {
        name: currentProduct.name,
        price: currentProduct.price,
        id: 23,
       // idCategory:currentProduct.idCategory,
        image: currentProduct.image,
        tva:currentProduct.tva,
        suggestion_produits:currentProduct.suggestion_produits,
        count: 1,
      },
    ]);

    dispatch(getProductsByCategories(currentProduct.idCategory)).then((res) => {
      if (Object.values(res.result.data).length !== 0) {
        setCategories(res.result.data.filter((val)=>val.id === currentProduct.id)[0].CategorieIngredients);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if(categories.length > 0 && countIngredient === 0 && maxStep == step){
      NextList()
    }
    if(countIngredient>0){
      setMaxStep(step)
    }
  }, [countIngredient])

  useEffect(() => {
    categories.length > 0 && countAvailableIngredient(categories[step].max,categories[step].categories_ingredient.id)
  }, [selected])

  const countAvailableIngredient = (max,idCategory) => {
    let count = 0;
    selected.map((el) =>
    el.idCategory === idCategory ? count=count+el.count : console.log('count')
  )
   setCountIngredient(max-count)
  }

  const Item = ({ type, name, price, image, id,max, count,idCategory, index }) => {
   
    const handleIngridient = () => {
      if (type === 0 && selected.every((val) => val.id !== id)) {
        countIngredient>0 &&  setSelected([...selected, { name, price, id,idCategory, image, count: 1 }]);
      } else {
        countIngredient>0 &&  setSelected(
          selected.map((el) =>
            el.id === id ? { ...el, count: el.count + 1 } : el
          )
        );
      }
    };

    const handleRemove = () => {
      if (type === 1 && index !== 0) {
        if (selected.filter((obj) => obj.id === id)[0].count === 1) {
          setSelected(selected.filter((obj) => obj.id !== id));
        } else {
          setSelected(
            selected.map((el) =>
              el.id === id ? { ...el, count: el.count - 1 } : el
            )
          );
        }
      }
    };

    return (
      <>
        <div
          onClick={() => (type !== 0 ? handleRemove() : handleIngridient())}
          style={{
            borderRadius: 15,
            marginRight:type !== 0 &&20,
            width: type !== 0 &&130,
            marginTop: 15,
            textAlign: 'center',
            boxShadow: '0px 0px 25px -9px rgba(0,0,0,0.33)',
            outline: 'none',
            background: '#fff',
            paddingTop: 10,
            paddingBottom: 10,
            position: 'relative',
            
          }}
        >
          {index !== 0 && (
            <Badge
              badgeContent={count}
              color='secondary'
              style={{
                right: 0,
                top: 5,
                position: 'absolute',
                borderTopLeftRadius: 15,
                fontSize: 14,
                fontWeight: 400,
              }}
            />
          )}

          {price && (
            <span
              style={{
                left: 0,
                position: 'absolute',
                marginTop: -10,
                background: '#ff0',
                padding: 4,
                borderTopLeftRadius: 15,
              }}
            >
              {price} €
            </span>
          )}
          <img src={process.env.REACT_APP_API_URL + image} width='90' alt='' />

          <Typography
            gutterBottom
            variant='h6'
            component='h2'
            style={{ fontSize: 15 }}
          >
            {name}
          </Typography>
        </div>
      </>
    );
  };

  const ingridients = (max) => {
    return ingredientData.map((resu, index) => (
      <Grid item xs={3}>
        <Item
          index={index}
          type={0}
          id={resu.id}
          name={resu.name}
          max={max}
          idCategory={resu.categories_ingredients[0].id}
          count={resu.count}
          price={resu.price}
          image={resu.image && resu.image.url}
        />
      </Grid>
    ));
  };

  const NextList = () => {
    if (categories.length > step + 1) {
      setStep(step + 1);
      if(maxStep < step + 1) {
        setMaxStep(step+1)
      }
    } else {
      dispatch(
        addProduct({
          productId: currentProduct.id,
          name: currentProduct.name,
          price: currentProduct.price,
          tva:currentProduct.tva,
          suggestion_produits:currentProduct.suggestion_produits,
          count: 1,
          ingridients: selected.filter((obj) => obj.id !== 23),
        })
      );
      gotToList();
    }
  };

  const handleSelectionCount = (count,index) => {
    
    //return count-selected[index].count;
  };

  const BackList = () => {
    if (step !== 0) {
      setStep(step - 1);
    }
  };

  return (
    <div>
      <p
        onClick={gotToList}
        style={{
          marginBottom: 20,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Icon>arrow_back</Icon> Précédent
      </p>
      {/* Votre selection  top */}
      <p style={{ fontWeight: 800, marginBottom: 20 }}>Votre selection</p>
      <div
        style={{
          display:"flex",
          overflowX: 'auto',
          marginTop: 5,
          marginBottom: 20,
        }}
      >
        {selected.map((val, index) => (
          <div>
            <Item
              index={index}
              type={1}
              id={val.id}
              name={val.name}
              count={val.count}
              price={val.price}
              image={val.image}
            />
          </div>
        ))}
      </div>

      {categories.map((item, index) => {
        if (step === index) {
          return (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 800,
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                <>
                  <p>{item.categories_ingredient.Title}</p>
                  <p>{countIngredient} selection</p>
                </>
              </div>

              <Grid
                container
                spacing={3}
                style={{
                  height: 270,
                  overflowY: 'auto',
                  marginTop: 5,
                  marginBottom: 20,
                }}
              >
                {ingridients(item.max)}
              </Grid>
            </div>
          );
        }
      })}

{categories.length>0 &&  categories[step].min<=(categories[step].max-countIngredient) &&  <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <img
            src={order.store !== undefined && order.store.iconRetour ? process.env.REACT_APP_API_URL + order.store.iconRetour.url  :precedent}
            alt=''
            width='31'
            style={{ cursor: 'pointer' }}
            onClick={BackList}
          />
        </div>
        <div>
          <img
            src={order.store !== undefined && order.store.iconSuivant ? process.env.REACT_APP_API_URL + order.store.iconSuivant.url  :suivant}
            alt=''
            width='31'
            style={{ cursor: 'pointer' }}
            onClick={NextList}
          />
        </div>
      </div>}

      {/*  or Choisissez vos sauces 3
      Sélection(s) on click Two or Souhaitez vous une frite item with Price list
      with react slick Vertical onclick on product ajouter on top back step next
      step */}
    </div>
  );
}
