import React, { useEffect,useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Button } from '../../components/Button';
import Typography from '@material-ui/core/Typography';
import { Select } from '../../components/Select';
import { useHistory } from 'react-router-dom';
import { Card } from '../../components/Card';
import queryString from 'query-string';
import moment from 'moment'
import 'moment/locale/fr'
import { useStyles } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrder } from '../../store/orders';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function OrderTime({ location }) {
  const classes = useStyles();
  const history = useHistory();
  const [type, setType] = useState();
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const dispatch = useDispatch();

  const order = useSelector((state) => state.reducerOrder.order);
  const { city, zip, street, orderDate, orderTime,typeDelivery,saveInfoAddress } = useSelector((state) => state.reducerOrder.order);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    pauseOnFocus: true,
    autoplaySpeed: 2000,
  };
  
  useEffect(() => {
    moment.locale('fr')
    const values = queryString.parse(location.search);
    setType(values.type);
  }, []);

  

  const handleChangeDate = (event) => {
    setDate(event.target.value);
    dispatch(updateOrder({ orderDate: event.target.value }))
  };

  const handleChangeTime = (event) => {
    setTime(event.target.value);
    dispatch(updateOrder({ orderTime: event.target.value }))
  };

  const getTimeStops=(start)=>{
   /* var startTime = moment(start,'HH:mm');
    var endTime = moment('00:00', 'HH:mm');
    
    if( endTime.isBefore(startTime) ){
      endTime.add(1, 'day');
    }
  
    var timeStops = [];
  
    while(startTime <= endTime){
      timeStops.push(new moment(startTime).format('HH:mm'));
      startTime.add(15, 'minutes');
    }
    return timeStops;*/
    const arr = [];

    for(let i=0; i<24; i++) {
      for(let j=0; j<4; j++) {
        arr.push(i + ":" + (j===0 ? "00" : 15*j) );
      }
    }
    const d = new Date()
    const h = d.getHours();
    const m = (15 * Math.floor(d.getMinutes()/15))
    const stamp = h + ":" + (m === 0 ? "00" : m);
    const  pos = arr.indexOf(stamp)
   
    return arr.slice(pos+1).concat(arr.slice(0,pos+1));;
  }

  const handleNow=()=>{
    history.push(`/products/category/${order.store.id}/1`)
    dispatch(updateOrder({ orderTime: 'now' }))
  }

  return (
   
      <Container >
        {order.store && order.store.slider.length>0 && <Slider {...settings}>
          { order.store.slider.map((item,index)=>(
            <div>
                    <img src={process.env.REACT_APP_API_URL + item.url} height={100} width={'100%'} style={{objectFit:'cover'}} />
                </div>
          ))}
                
                </Slider>}
      <Container maxWidth='sm'>
       
        <Typography
          variant='h5'
          component='h2'
          align='center'
          className={classes.title}
        >
          {type==='pickup'?"À VENIR CHERCHER AU MAGASIN":"Livrée à l'adresse"}
        </Typography>
        <Grid container justify='center'>
        <Grid container justify='center'>
          {city&&<Card
            key={1}
            action={true}
            title={`${city}`}
            onClick={()=>history.goBack()}
            description={`${zip}, ${street}`}
          />}
          <Typography variant='h6' component='h2' align='center'>
            A QUEL MOMENT SOUHAITEZ-VOUS VOTRE COMMANDE ?
          </Typography>
          <div className={classes.container}>
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonDate}
              handleAction={() => handleNow()}
            >
              Dès que possible
            </Button>
            <p style={{ margin: 20 }}>OU</p>
            <div className={classes.content}>
              <Select
                label={'DATE DE LA COMMANDE'}
                value={orderDate}
                handleChange={handleChangeDate}
                options={[moment().format('LL')]}
              />

              <Select
                label={'HORAIRE DE LA COMMANDE'}
                value={orderTime}
                handleChange={handleChangeTime}
                options={getTimeStops(moment())}
              />
            </div>
          </div>
          <div className={classes.bottomNavigation}>
            <Button variant='contained' handleAction={() => history.goBack()}>
              Précédent
            </Button>
            <Button
              variant='contained'
              handleAction={() => history.push(
                {
                  pathname: `/products/category/${order.store.id}/1`,
                  state:'test'
              })}
              color='primary'
              disabled={!orderDate || !orderTime}
            >
              Commencer la commande
            </Button>
          </div>
        </Grid>
        </Grid>
      </Container>
      </Container>
   
  );
}
