import React, { lazy, Suspense, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { getProductsByCategories } from 'store/products';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
const ItemProduct = lazy(() => import('./item.jsx'));

const Spinner = () => {
  return null;
};

export default function Section({
  goToDetails,
  handleDetails,
  handleAddProduct,
}) {
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const [data, setData] = useState();

  // update on change category
  useEffect(() => {
    dispatch(getProductsByCategories(categoryId)).then((res) => {
      setData(res.result.data);
    });
  }, [categoryId, dispatch]);

  return (
    <>
      <Grid container spacing={2}>
        {data && data.length > 0 ?data.map((item, index) => (
          <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
            <Suspense fallback={<Spinner />}>
              <ItemProduct
                index={index}
                id={item.id}
                tva={item.tva}
                description={item.description}
                suggestion_produits={item.suggestion_produits}
                idCategory={item.categories.id}
                handleDetails={(item)=>handleDetails(item)}
                handleAddProduct={handleAddProduct}
                goToDetails={goToDetails}
                image={item.Photo && item.Photo.url}
                name={item.nom}
                price={item.PrixTTC}
                config={item.Config}
              />
            </Suspense>
          </Grid>
        )) : data && 'PAS DE PRODUIT POUR LE MOMENT'}
      </Grid>
    </>
  );
}
