import React, { useState } from 'react';
import Section from './section';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import StaticBasket from './staticBasket';
import StaticBasketMobile from './staticBasketMobile';
import Categories from './categories';
import Details from './details.jsx';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '../../components/Button';
import hero_7565 from '../../assets/hero_7565.webp';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct, updateProduct } from '../../store/cart';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

export default function ProductMenu() {
  const [data, setData] = useState([]);
  const [changeDetails, setChangeDetails] = useState(true);
  const [currentProduct, setCurrentProduct] = useState(null);
  const { path } = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.Cart.cart);

  const handleDetails = (item) => {
    setOpen(!open);
    setDetails(item)
  };

  const gotToList = () => {
    setChangeDetails(true);
  };

  const goToDetails = (id,idCategory, name, price, image, tva,suggestion_produits) => {
    setCurrentProduct({ id,idCategory, name, price,tva, image,suggestion_produits });
    setChangeDetails(false);
  };

  const handleAddProduct = (productId, name, price,tva,suggestion_produits) => {
    if (cart.every((item) => item.productId !== productId)) {
      dispatch(addProduct({ productId, name, price,tva,suggestion_produits, count: 1 }));
    } else {
      dispatch(updateProduct(productId));
    }
  };

  return (
    <Container maxWidth='xl'>
      <Grid container>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <Switch>
            <Route path={`${path}/category/:storeId/:categoryId`}>
              {changeDetails && (
                <div>
                  <Categories />
                </div>
              )}
              <div style={{ marginBottom: 90 }}>
                {changeDetails ? (
                  <Section
                    goToDetails={goToDetails}
                    handleAddProduct={handleAddProduct}
                    data={data}
                    handleDetails={(item)=>handleDetails(item)}
                  />
                ) : (
                  <Details
                    gotToList={gotToList}
                    currentProduct={currentProduct}
                  />
                )}
              </div>
            </Route>
          </Switch>
        </Grid>
        <Hidden smDown>
          <Grid item xs={3}>
            <StaticBasket />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <StaticBasketMobile />
        </Hidden>

        <Dialog
          onClose={handleDetails}
          scroll={'body'}
          aria-labelledby='simple-dialog-title'
          open={open}
        >
          <div style={{ textAlign: 'center' }}>
            <img src={ details &&process.env.REACT_APP_API_URL + details.image} style={{ width: '60%' }} alt='' />
            <div style={{ textAlign: 'left', padding: 15 }}>
                <p>{details && details.name}</p>
              <h4>description du produit</h4>
              <p>
              {details && details.description}
              </p>
            </div>

            <Button
              variant='contained'
              color='primary'
              style={{ marginBottom: 20 }}
              handleAction={() => handleDetails()}
            >
              Retour
            </Button>
          </div>
        </Dialog>
      </Grid>
    </Container>
  );
}
