import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/configStore';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Home from './views/Home';
import Pickup from './views/Pickup';
import Delivery from './views/Delivery';
import OrderTime from './views/OrderTime';
import ProductMenu from './views/ProductMenu';
import Payment from './views/Payment';
import Register from './views/Register';
import Checkout from './views/Checkout';
import Suggestion from './views/Suggestion';
import Successful from './views/Checkout/successful';
import Cancel from "./views/Checkout/cancel"
import Cash from "./views/Checkout/cash"

import { theme } from './theme';
import './App.css';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <div style={{ paddingTop: 40 }}>
              <Route exact path={['/', '/home']} component={Home} />
              <Route path='/pickup' component={Pickup} />
              <Route path='/delivery' component={Delivery} />
              <Route path='/order-time' component={OrderTime} />
              <Route path='/products' component={ProductMenu} />
              <Route exact path='/payment' component={Payment} />
              <Route exact path='/suggestion' component={Suggestion} />
              <Route path='/checkout/:token' component={Checkout} />
              <Route path='/successful/:ref' component={Successful} />
              <Route path='/cancel/:ref' component={Cancel} />
              <Route path='/cash' component={Cash} />
              <Route path='/register' component={Register} />
            </div>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
