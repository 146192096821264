import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const CheckboxField = ({
  checked,
  label,
  name,
  onChange,
  ...props
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          color='primary'
          name={name}
        />
      }
      label={label}
    />
  );
};
