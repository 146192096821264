import { GET_PRODUCTS } from './constants';

/**
 * Get categories
 */
export const getProductsByCategories = (categoriesId) => ({
  type: GET_PRODUCTS,
  url: `/produits/?categories.id=${categoriesId}`,
  method: 'GET',
});
