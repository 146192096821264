import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import { createOrder } from 'store/orders';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Checkout = (props) => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [paylineToken, setPaylineToken] = useState(token);

  useEffect(() => {
  }, []);
  const paylineBaseUrl =
    process.env.REACT_APP_ENV === 'production'
      ? 'https://payment.payline.com'
      : 'https://homologation-payment.payline.com';

  return (
    <Container maxWidth='xs'>
      <Helmet>
        <script src={`${paylineBaseUrl}/scripts/widget-min.js`} />
        <link
          href={`${paylineBaseUrl}/styles/widget-min.css`}
          rel='stylesheet'
        />
      </Helmet>
      <Typography
        variant='h5'
        component='h2'
        align='center'
        style={{ marginBottom: 20, textTransform: 'uppercase', fontSize: 20 }}
      >
        choisissez un moyen de paiement
      </Typography>
      <Grid container justify='center'>
        <Grid item md={12}>
          <div
            data-token={paylineToken}
            data-template={'column'}
            id='PaylineWidget'
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Checkout;
