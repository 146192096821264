import { ADD_PRODUCTS, REMOVE_PRODUCTS, UPDATE_PRODUCTS } from './constants';

/**
 * Add product to cart
 */
export const addProduct = (product) => ({
  type: ADD_PRODUCTS,
  payload: product,
});

/**
 * Remove product from cart
 */
export const removeProduct = (id) => ({
  type: REMOVE_PRODUCTS,
  payload: id,
});

/**
 * Update product in cart
 */
export const updateProduct = (id) => ({
  type: UPDATE_PRODUCTS,
  payload: id,
});
