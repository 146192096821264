import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import surPlaceIcn from '../../assets/command_type/sur_place.png';
import aEmporterIcn from '../../assets/command_type/a_emporter.png';
import Typography from '@material-ui/core/Typography';
import { ButtonWithIcon } from '../../components/ButtonWithIcon';
import { useDispatch } from 'react-redux';
import { updateOrder } from '../../store/orders';

const Home = () => {
  const dispatch = useDispatch();

  const handelUpdateOrder = (value) => {
    dispatch(updateOrder({ type: value }));
  };

  return (
    <Container maxWidth='xs'>
      <Typography variant='h5' component='h2' align='center'>
        CHOISISSEZ VOTRE TYPE DE COMMANDE
      </Typography>
      <Grid container justify='center'>
        <Grid item md={6}>
          <ButtonWithIcon
            icon={aEmporterIcn}
            link={'/delivery'}
            onClick={() => handelUpdateOrder('delivery')}
            title={'LIVRAISON'}
          />
        </Grid>
        <Grid item md={6}>
          <ButtonWithIcon
            icon={surPlaceIcn}
            link={'/pickup'}
            onClick={() => handelUpdateOrder('pickup')}
            title={'A EMPORTER'}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
