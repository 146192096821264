import { GET_STORES,GET_STORES_BYID } from './constants';

/**
 * Get stores
 */
export const getStores = (search) => ({
  type: GET_STORES,
  url: `/magasins?codepostal_containss=${search}`,
  method: 'GET',
});

/**
 * Get stores by id
 */
export const getStoresById = (id) => ({
  type: GET_STORES_BYID,
  url: `/magasins/${id}`,
  method: 'GET',
});
