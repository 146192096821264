import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Card } from '../../../components/Card';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { updateOrder } from 'store/orders';
import { useHistory } from 'react-router-dom';

const FormPickup = ({getStoresHandler,data}) => {
  
  const dispatch = useDispatch();
  const history = useHistory();

  const selectStore = (store) =>{
    dispatch(updateOrder({ store })).then(()=>history.push('/order-time'))
  }
  return (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <TextField
            size='small'
            label='VOTRE CODE POSTAL'
            variant='outlined'
            style={{
                    width: '100%',
                    marginBottom: 10,
                    background: '#fff',
                  }}
                  onChange={(event)=>getStoresHandler(event.target.value)}
                />
      </Grid>
      <h3>SÉLECTIONNEZ VOTRE BOUTIQUE</h3>
      {data.map((item, index) => (
        <Card
          key={index}
          title={item.Ville}
          description={`${item.Adresse} - ${item.codepostal}`}
          onClick={()=>selectStore(item)}
        />
      ))}
    </Grid>
  );
};

export default memo(FormPickup);
