import React, { memo } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap defaultZoom={8} defaultCenter={props.latLng}>
      {props.isMarkerShown &&
        props.data.length > 0 &&
        props.data.map((item, index) => (
          <Marker
            position={item.latLng}
            onClick={() => props.selectStore(item.store)}
          />
        ))}
    </GoogleMap>
  ))
);

export default memo(Map);
