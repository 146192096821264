import React, { memo } from 'react';
import { Card } from '../../../components/Card';
import { useDispatch } from 'react-redux';
import { updateOrder } from 'store/orders';
import { useHistory } from 'react-router-dom';



const List = ({data}) => {

  const dispatch = useDispatch();
const history = useHistory();

const selectStore = (store) =>{
  dispatch(updateOrder({ store })).then(()=>history.push('/order-time'))
}
  return (
    <>
      {data.length>0 && data.map((item, index) => (
       item.distance<1484 && <Card
          key={index}
          title={item.store.Ville}
          description={`${item.store.Adresse} - ${item.store.codepostal}`}
          onClick={()=>selectStore(item.store)}
        />
      ))}
    </>
  );
};

export default memo(List);
